import React, { useState } from 'react';
import { Link } from 'gatsby-plugin-intl';
import Language from './Language';

const HeaderMobile = ({ searchValue, searchOnChange, searchText, search }) => {
  const [isHidden, setIsHidden] = useState(true);
  return (
    <div className="header-mobile mobile">
      <div className="header-mobile__container">
        <Link to="/">
          <div className={`header-mobile__logo ${!isHidden ? 'hidden' : ''}`} />
        </Link>
        {search ? (
          <label className={`header-mobile_search ${isHidden ? 'hidden' : ''}`}>
            <span
              className={`searchIcon ${isHidden ? 'hidden' : ''}`}
              onClick={(event) => {
                isHidden && setIsHidden(false);
              }}
            />
            <input
              value={searchValue}
              onChange={searchOnChange}
              className={`header-mobile_search__field ${
                isHidden ? 'hidden' : ''
              }`}
              type="text"
              placeholder={searchText}
            />
            <span
              className={`closeIcon ${isHidden ? 'hidden' : ''}`}
              onClick={(event) => {
                setIsHidden(true);
              }}
            />
          </label>
        ) : (
          <div />
        )}
        <Language className="header__language" />
      </div>
    </div>
  );
};

export default HeaderMobile;
