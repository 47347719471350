import { makeAutoObservable } from 'mobx';

const dark = {
  backColor: 'black',
  color: 'white',
};

const light = {
  backColor: 'white',
  color: 'black',
};

const themeStore = () => ({
  theme: dark,

  nowIsDark: true,

  setTheme(isDark) {
    this.theme = isDark ? dark : light;
    this.nowIsDark = isDark;
  },

  toggleTheme() {
    this.theme = this.nowIsDark ? light : dark;
    this.nowIsDark = !this.nowIsDark;
  },
});

export default makeAutoObservable(themeStore());
