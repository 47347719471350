import React, { useState } from 'react';
import { Link } from 'gatsby-plugin-intl';
import Language from './Language';

const Header = ({ searchValue, searchOnChange, searchText, search }) => {
  return (
    <div className="header desktop-tablet">
      <div className="header__container">
        <Link to="/">
          <div className={`header__logo`} />
        </Link>
        {search ? (
          <label className="header_search">
            <input
              value={searchValue}
              onChange={searchOnChange}
              className="header_search__field"
              type="text"
              placeholder={searchText}
            />
          </label>
        ) : (
          <div />
        )}
        <Language className="header__language" />
      </div>
    </div>
  );
};

export default Header;
