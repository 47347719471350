import React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';

const Sidebar = () => {
  return (
    <div className="sidebar">
      {/* <Link to="/" className="sidebar_item" activeClassName="active">
        <div className="sidebar_item__icon __home" />
        <FormattedMessage id="home" />
      </Link> */}
      <Link to="/guide" className="sidebar_item" activeClassName="active">
        <div className="sidebar_item__icon __guide" />
        <FormattedMessage id="guide" />
      </Link>
      {/* <Link to="/download" className="sidebar_item" activeClassName="active">
        <div className="sidebar_item__icon __download" />
        <FormattedMessage id="download" />
      </Link> */}
      {/* <Link to="/blog" className="sidebar_item" activeClassName="active">
        <div className="sidebar_item__icon __blog" />
        <FormattedMessage id="blog" />
      </Link> */}
      <Link to="/donate" className="sidebar_item" activeClassName="active">
        <div className="sidebar_item__icon __support" />
        <FormattedMessage id="donate" />
      </Link>
      <Link to="/vacancy" className="sidebar_item" activeClassName="active">
        <div className="sidebar_item__icon __vacancy" />
        <FormattedMessage id="vacancy" />
      </Link>
      <Link to="/about" className="sidebar_item" activeClassName="active">
        <div className="sidebar_item__icon __about" />
        <FormattedMessage id="about" />
      </Link>
    </div>
  );
};
export default Sidebar;

// <div className="sidebar">
//   <Link to="/" className="sidebar_item" activeClassName="active">
//     <div className="sidebar_item__icon __home" />
//     Home
//   </Link>
//   <Link to="/blog" className="sidebar_item" activeClassName="active">
//     <div className="sidebar_item__icon __blog" />
//     Blog
//   </Link>
//   <Link to="/support" className="sidebar_item" activeClassName="active">
//     <div className="sidebar_item__icon __support" />
//     Support
//   </Link>
//   <Link to="/download" className="sidebar_item" activeClassName="active">
//     <div className="sidebar_item__icon __download" />
//     Download
//   </Link>
//   <Link to="/about-us" className="sidebar_item" activeClassName="active">
//     <div className="sidebar_item__icon __about" />
//     About Us
//   </Link>
// </div>;
