import React from 'react';
import { Link } from 'gatsby-plugin-intl';

const MobileNav = () => {
  return (
    <div className="mobileNav">
      {/* <Link to="/" className="mobileNav_item" activeClassName="active">
        <div className="mobileNav_item__icon __home" />
      </Link> */}
      <Link to="/guide" className="mobileNav_item" activeClassName="active">
        <div className="mobileNav_item__icon __guide" />
      </Link>
      {/* <Link to="/blog" className="mobileNav_item" activeClassName="active">
        <div className="mobileNav_item__icon __blog" />
      </Link> */}
      <Link to="/donate" className="mobileNav_item" activeClassName="active">
        <div className="mobileNav_item__icon __support" />
      </Link>
      {/* <Link to="/download" className="mobileNav_item" activeClassName="active">
        <div className="mobileNav_item__icon __download" />
      </Link> */}
      <Link to="/vacancy" className="mobileNav_item" activeClassName="active">
        <div className="mobileNav_item__icon __vacancy" />
      </Link>
      <Link to="/about" className="mobileNav_item" activeClassName="active">
        <div className="mobileNav_item__icon __about" />
      </Link>
    </div>
  );
};

export default MobileNav;

// <div className="mobileNav">
//   <Link to="/" className="mobileNav_item" activeClassName="active">
//     <div className="mobileNav_item__icon __home" />
//   </Link>
//   <Link to="/blog" className="mobileNav_item" activeClassName="active">
//     <div className="mobileNav_item__icon __blog" />
//   </Link>
//   <Link to="/support" className="mobileNav_item" activeClassName="active">
//     <div className="mobileNav_item__icon __support" />
//   </Link>
//   <Link to="/download" className="mobileNav_item" activeClassName="active">
//     <div className="mobileNav_item__icon __download" />
//   </Link>
//   <Link to="/about-us" className="mobileNav_item" activeClassName="active">
//     <div className="mobileNav_item__icon __about" />
//   </Link>
// </div>;
