import React from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const languageName = {
  en: 'English',
  ru: 'Русский',
  uk: 'Українська',
  zhHant: 'Chinese (Traditional)',
  zhCN: 'Chinese (Simplified)',
};

const Language = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <Dropdown
          options={languages}
          onChange={({ value }) => changeLocale(value)}
          value={currentLocale}
        />
      )}
    </IntlContextConsumer>
  );
};

export default Language;
