/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-intl';
import { StaticQuery, graphql, useStaticQuery } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import '../styles/index.sass';
import Language from '../components/Language';
import themeStore from '../stores/themeStore';
import { observer } from 'mobx-react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import MobileNav from '../components/MobileNav';
import HeaderMobile from '../components/HeaderMobile';

const query = graphql`
  query LayoutQuery {
    datoCmsSite {
      globalSeo {
        siteName
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      introTextNode {
        childMarkdownRemark {
          html
        }
      }
      copyright
    }
    allDatoCmsSocialProfile(
      filter: { locale: { eq: "en" } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          profileType
          url
        }
      }
    }
  }
`;

const TemplateWrapper = ({ children, seo, search, siteData = {} }) => {
  const [showMenu, setShowMenu] = useState(false);
  const data = useStaticQuery(query);
  return (
    <>
      <Header
        search={search}
        searchText={siteData.searchText}
        searchValue={siteData.searchValue}
        searchOnChange={siteData.searchOnChange}
      />
      <HeaderMobile
        search={search}
        searchText={siteData.searchText}
        searchValue={siteData.searchValue}
        searchOnChange={siteData.searchOnChange}
      />
      <div className={`container ${showMenu ? 'is-open' : ''}`}>
        {/* Global seo */}
        <HelmetDatoCms
          favicon={data.datoCmsSite.faviconMetaTags}
          seo={data.datoCmsHome.seoMetaTags}
        />

        {/* Local seo */}
        <HelmetDatoCms seo={seo} />
        <Sidebar />
        <div className="container__body">{children}</div>
      </div>
      <MobileNav />
    </>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object,
};

export default observer(TemplateWrapper);
/* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/
